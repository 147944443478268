<template>
	<div class="pagination">
		<p>{{ summary }}</p>

		<div class="actions">
			<div class="action border-left" @click="decrementPageNum">
				<img src="@/assets/img/icons/back-icon.png" />
			</div>

			<div v-for="item of list" :key="item">
				<div
					:class="`action ${item == data.page ? 'active' : ''}`"
					@click="setPageNum(item)"
					>
					{{ item }}
				</div>
			</div>

			<div class="action border-right" @click="incrementPageNum">
				<img src="@/assets/img/icons/forward-icon.png" />
			</div>
		</div>
	</div>
</template>
<script>
export default {
  props: ['data', 'currentPage'],
  data () {
    return {}
  },
  computed: {
    list () {
      const { totalItems, pageSize, page, lastPage } = this.data
      const d = Math.ceil(totalItems / 10)

      const list = []
      for (let i = 1; i <= d; i++) {
        list.push(i)
      }

      return list
    },
    setPageSize () {
      return this.data.setPageSize
    },
    setPageNum () {
      return this.data.setPageNum
    },
    summary () {
      const { totalItems, pageSize, page, lastPage } = this.data
      return `${totalItems ? (page - 1) * pageSize + 1 : 0} - ${
        totalItems
          ? page === totalItems / pageSize
            ? totalItems
            : page * pageSize
          : 0
      } of ${totalItems || 0}`
    }
  },

  methods: {

    incrementPageNum () {
      const { totalItems, pageSize, lastPage, page } = this.data


      this.setPageNum(page < totalItems / pageSize ? page + 1 : page)
    },
    decrementPageNum () {
      const { totalItems, lastPage, page } = this.data

      this.setPageNum(page > 1 ? page - 1 : page)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding-bottom: 20px;
}
.actions {
  user-select: none;
  display: flex;
  .action {
    cursor: pointer;
    // font-size: 12px;
    img {
      width: 12px;
      height: 12px;
    }

    transition: 0.5s;
    border: 1px solid #d0d5dd;
    border-right: none;
    // padding: 14px;
    height: 30px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: #d6dbe3;
    }
    &:hover {
      background: #e2e7ef;
    }
    &.border-left {
      // border-left: 1px solid #D0D5DD;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.border-right {
      // border-left: 1px solid #D0D5DD;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
